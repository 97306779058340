.event {
    width: 300px;
    height: 350px;
    border: 2px solid rgba(255, 86, 35, 1);
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}


.event h3 {
    font-size: 26px;
    position: relative;
}

.event h3::before {
    content: '';
    position: absolute;
    top: -1rem;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background: rgba(255, 86, 35, 1);
}

.event span {
    font-size: 16px;
    color: rgba(255, 86, 35, 1);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.event span img {
    margin-right: .5rem;
}

.event p {
    margin-top: 2rem;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.edit-delete {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.edit-delete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}

.yellow {
    border: 2px solid rgba(255, 151, 1, 1);
}

.yellow h3::before {
    background: rgba(255, 151, 1, 1);
}

.yellow span {
    color: rgba(255, 151, 1, 1);
}