.main-body {
    width: 70%;
    height: max-content;
    margin-right: 1rem;
    float: right;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10rem;
}