@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;500&family=Raleway:wght@100;300;400;500;600;700&display=swap);
.navbar {
    width: 95vw;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
}

.navbar img {
    width: 50px;
}

.navbar div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar h1 {
    margin-left: 1rem;
}

.navbar p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: rgba(81, 81, 81, 1);
}
.side-bar {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: 1rem;
    height: 70vh;
    border-right: 1px solid rgba(202, 202, 202, 1);
    float: left;
}

.side-bar ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.side-bar a {
    width: 215px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    font-family: 'poppins', sans-serif;
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
}

.side-bar li:hover {
    background-color: lightgray;
    cursor: pointer;
}
h1 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}
.form-check {
    padding: 1rem;
    margin: 1rem;
}
.event {
    width: 300px;
    height: 350px;
    border: 2px solid rgba(255, 86, 35, 1);
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}


.event h3 {
    font-size: 26px;
    position: relative;
}

.event h3::before {
    content: '';
    position: absolute;
    top: -1rem;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background: rgba(255, 86, 35, 1);
}

.event span {
    font-size: 16px;
    color: rgba(255, 86, 35, 1);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.event span img {
    margin-right: .5rem;
}

.event p {
    margin-top: 2rem;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.edit-delete {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.edit-delete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}

.yellow {
    border: 2px solid rgba(255, 151, 1, 1);
}

.yellow h3::before {
    background: rgba(255, 151, 1, 1);
}

.yellow span {
    color: rgba(255, 151, 1, 1);
}
.main-body {
    width: 70%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin-right: 1rem;
    float: right;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10rem;
}
