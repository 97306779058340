.side-bar {
    width: max-content;
    margin-left: 1rem;
    height: 70vh;
    border-right: 1px solid rgba(202, 202, 202, 1);
    float: left;
}

.side-bar ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.side-bar a {
    width: 215px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    font-family: 'poppins', sans-serif;
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
}

.side-bar li:hover {
    background-color: lightgray;
    cursor: pointer;
}