.navbar {
    width: 95vw;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
}

.navbar img {
    width: 50px;
}

.navbar div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar h1 {
    margin-left: 1rem;
}

.navbar p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: rgba(81, 81, 81, 1);
}